<template>
  <div class="report-template" v-loading="loading">
    <div class="gradient-bg"></div>
    <img class="pdm-logo" src="~assets/home/logo-black.png" alt="" />
    <div class="report-name">PDM Analysis Report</div>
    <div class="template-box">
      <p class="title"><span class="title-circle"></span>Report Information</p>
      <table class="song-table">
        <tr>
          <th>Track Name</th>
          <th>Artist</th>
          <th>Play</th>
          <th>Company</th>
          <th>Report Time</th>
        </tr>
        <tr class="track-info">
          <td>{{songData.track_name}}</td>
          <td>{{songData.artist || '--'}}</td>
          <td>
            <i
              class="player"
              :class="isAudioPlay ? 'icon-pause1' : 'icon-play1'"
              @click="playMusic()"
            >
            </i>
            <audio id="audioPlayer" :src="songData.track_cos_link"></audio>
          </td>
          <td>{{songData.company}}</td>
          <td>{{songData.update_time}}</td>
        </tr>
      </table>
    </div>

    <div class="template-box">
      <p class="title"><span class="title-circle"></span>PDM Score</p>
      <pdm-score :song-data="songData"></pdm-score>
    </div>

    <div class="template-box">
      <p class="title"><span class="title-circle"></span>Playlist Analysis</p>
      <p class="second-title">PDM reveals the playlists options for <b>pitching new releases</b>. The listed playlists are selected based on comprehensive consideration of <b>relevancy of content, playlist traffic </b>and<b> track quantity</b> on various streaming services. <br>You can filter the playlists result by draging the scroll bar to adjust range of playlist like counts.</p>

      <el-radio-group v-model="playlistsCompany">
        <el-radio-button label="spotify_playlist">Spotify</el-radio-button>
        <el-radio-button label="apple_playlist">Apple Music</el-radio-button>
        <el-radio-button label="amazon_playlist">Amazon Music</el-radio-button>
        <el-radio-button label="boomplay_playlist">Boomplay</el-radio-button>
        <el-tooltip content="Coming Soon">
          <el-radio-button disabled label="deezer_playlist">Deezer</el-radio-button>
        </el-tooltip>
      </el-radio-group>

      <div class="filter-box">
        <div v-if="playlistsCompany === 'spotify_playlist' || playlistsCompany === 'boomplay_playlist'" class="filter-item">
          <span>Like counts:</span>
          <el-slider
            class="slider"
            v-model="likesRange"
            range
            show-stops
            :max="100"
            :marks="marks"
            :format-tooltip="formatTooltip">
          </el-slider>
        </div>
        <div v-if="playlistsCompany !== 'boomplay_playlist'" class="filter-item">
          <span>Theme:</span>
          <el-select v-model="theme">
            <el-option label="All" value="All"></el-option>
            <el-option v-for="(item, index) in platformTags" :key="index" :label="item.type" :value="item.type">
              <div style="display:flex; justify-content: space-between; align-items: center;">
                <p>{{ item.type }}</p>
                <p style="color: #FF9700; font-size: 13px; border: 1px solid #FF9700; height: 20px; width: 30px; text-align:center; line-height: 20px; border-radius: 4px;">
                  {{ item[`${currentPlatform}_count`] }}
                </p>
              </div>
            </el-option>
          </el-select>
        </div>
        <div v-if="playlistsCompany !== 'boomplay_playlist'" class="filter-item">
          <span>Curated by:</span>
          <el-select v-model="creator">
            <el-option label="All" value="All"></el-option>
            <el-option :label="$util.getUpperCase(currentPlatform)" value="1"></el-option>
            <el-option label="users" value="0"></el-option>
          </el-select>
        </div>
        <div v-if="playlistsCompany === 'boomplay_playlist'" class="filter-item">
          <span>Region:</span>
          <el-select v-model="region">
            <el-option label="All" value="All"></el-option>
            <el-option v-for="item in regionList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </div>
      </div>

      <div class="playlist-box">
        <div v-if="playlists.length !== 0" class="playlist-div">
          <div 
            class="playlist-item" 
            v-for="(list,index) in playlists" 
            :key = index
            @click="gotoPlaylist(list.link)"
          > 
              <div class="img-box">
                <img 
                  class="search-img" 
                  :src="list.cover"
                >
              </div>
              <div class="info-box">
                <p class="playlist-link">{{ list.title }}</p>
                <div class="fs14">
                  <span v-if="playlistsCompany === 'spotify_playlist' || playlistsCompany === 'boomplay_playlist'" class='mr30'>Likes: {{ Number(+list.likes).toLocaleString() }} </span>
                  <span class='mr30'>Songs: {{ list.song_num }}</span>
                  <span v-if="playlistsCompany !== 'boomplay_playlist'" class='mr30'>Theme: {{ list.type }}</span>
                  <span v-if="playlistsCompany === 'boomplay_playlist'" class='mr30'>Region: {{ list.region }}</span>
                </div>
              </div>
          </div>
        </div>
        <my-empty
          v-if="playlists.length === 0"
          slot="empty"
          empty-text="No data at present"
        ></my-empty>

        <el-pagination
          class="tar mt10"
          layout="prev, pager, next"
          :page-size="5"
          :current-page="currentPage"
          :total="totalPlaylist.length"
          @current-change="changePlaylist"
        >
        </el-pagination>
      </div>
    </div> 

    <div class="template-box">
      <p class="title"><span class="title-circle"></span>Audience Profile Prediction</p>
      <p class="second-title"><b>Predict target audience</b> of up-coming releases before any listener engagement data available. The prediction is based on AI analysis of <b>artist data</b>, <b>music content (audio&lyrics)</b> and <b>track-down of changing music trends</b>  around the world. Determine which market best fits the new release and optimize promotion strategies ahead of time. <br> You can filter the countries and regions result on the map by draging the scroll bar to adjust range of score.</p>
      <div class="chart-box" >
        <div class="map-chart" id="map"></div>
      </div>
      <div class="top30-region">
        <p class="top30-text">Top30 Regions</p>
        <div class="top-10">
          <div v-for="item in region10" :key="item.name">
            {{ item.name }}
          </div>
        </div>
        <div class="top-20">
          <div v-for="item in region20" :key="item.name">
            {{ item.name }}
          </div>
        </div>
        <div class="top-30">
          <div v-for="item in region30" :key="item.name">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="template-box">
      <p class="title"><span class="title-circle"></span>Short-video Promotion Analysis</p>
      <p class="second-title">PDM studies the track and selects most viral-potential part, predicting <b>viral-worthy short-video promotion ideas</b> by auto-matching the catchy hook to trending and content-relevant UGC content on TikTok. From a simulation of trending videos curated with your music, get inspired about how to make up-coming release go viral.</p>

      <div v-if="!videoTypes[0]" class="video-empty">
        <my-empty
          slot="empty"
          empty-text="The track doesn't seem to be able to discover a great match of short video suggestions. <br>PDM suggests experimenting with different promotion strategies."
        ></my-empty>
      </div>

      <div v-if="videoTypes[0]" class="video-type">
        <p>Video theme: </p>
        <el-radio-group class="video-type-group ml20" v-model="videoType" @change="changeVideoType">
          <el-radio-button v-for="(type ,index) in videoTypes" :key="type" :label="index">{{ type }}</el-radio-button>
        </el-radio-group>
      </div>
      <div class="video-list">
        <video  
          v-for="(item,index) in videoUrlList" 
          :key="index" 
          :src="item" 
          @play="handlePlay (index)"
          class="video-item" 
          controls="controls" 
          >
          您的浏览器不支持 video 标签。
        </video>
      </div>
    </div>
    
    <p class="email-us">For any enquiries, please email us at PDM@tencent.com. </p>
    <img class="pdm-bg" src="../../assets/pdm-bg.png">
  </div>
</template>

<script>
import PdmScore from '../common/__pdm-score.vue';
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/graph"
import "echarts/map/js/world.js"
import "echarts/lib/component/tooltip";
import mapData from '../common/world.json';

export default {
  components: {
    PdmScore
  },

  props: {
    options: {
      type: Object,
    },
  },

  data() {
    return {
      currentPage: 1,
      currentPlatform: 'spotify',
      platformTags: [],
      songData: {
        track_name: "",
        artist: "",
        company: "",
        update_time: ""
      },
      pdmResult: [],
      playlistsCompany:'spotify_playlist',
      worldmapData: [],
      region10: [],
      region20: [],
      region30: [],
      promotionData:[],
      videoTypes:[],
      videoType:0,
      videoUrlList:[],
      totalPlaylist: [],
      playlists:[],
      mapChart:null,
      graphChart:null,
      audioPlayer:'',
      isAudioPlay:'',
      videoElement:[],
      loading:true,
      likesRange: [0, 100],
      marks: {
        0: '0',
        25: '1,000',
        50: '10,000',
        75: '100,000',
        100: '1,000,000'
      },
      theme: 'All',
      creator: 'All',
      region: 'All',
      regionList: [],
      // 各平台歌单信息
      playlistObj: {}
    }
  },

  watch: {
    likesRange () {
      this.filterPlaylist()
    },

    theme () {
      this.filterPlaylist()
    },

    creator () {
      this.filterPlaylist()
    },

    playlistsCompany (val) {
      if (val === 'boomplay_playlist') {
        this.marks = {
          0: '0',
          25: '100',
          50: '1,000',
          75: '10,000',
          100: '100,000'
        }
      } else {
        this.marks = {
          0: '0',
          25: '1,000',
          50: '10,000',
          75: '100,000',
          100: '1,000,000'
        }
      }
      // 切换平台时，对主题、创作者、地区、点赞量范围进行初始化
      this.theme = 'All'
      this.creator = 'All'
      this.region = 'All'
      this.likesRange = [0, 100]
      this.currentPlatform = val.split('_')[0] || ''
      this.platformTags = this.playlistObj.playlist_tags.filter(item => item[`${this.currentPlatform}_count`])
      this.filterPlaylist()
    },

    region () {
      this.filterPlaylist()
    }
  },

  mounted() {
    this.getData()
    localStorage.setItem("lang", "en")
    this.$i18n.locale = localStorage.getItem("lang")
  },

  methods: {
    formatTooltip (value) {
      const max = this.playlistsCompany === 'boomplay_playlist' ? 100 : 1000
      if (value >= 0 && value <=25) {
        return Math.ceil(value*max/25)
      }
      if (value > 25 && value <=50) {
        return Math.ceil((value-25)*9*max/25 + max)
      }
      if (value > 50 && value <=75) {
        return Math.ceil((value-50)*90*max/25 + 10*max)
      }
      if (value > 75 && value <=100) {
        return Math.ceil((value-75)*900*max/25 + 100*max)
      }
    },

    gotoPlaylist(link) {
      window.open(link)
    },

    drawMap(){
      // 基于准备好的dom，初始化echarts实例
      let chart = echarts.init(document.getElementById('map'))
      // 监听屏幕变化自动缩放图表
      window.addEventListener('resize', function () {
        chart.resize()
      })
      // 绘制图表
      chart.setOption({
        grid: {
          width:'100%',
          height:'100%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        // 提示框组件
        tooltip: {
          trigger: 'item', // 触发类型, 数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用
          // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式
          // 使用函数模板  传入的数据值 -> value: number | Array
          formatter: function (val) {
            if(val.data == null) return ;
            return val.data.name + ': ' + val.data.value + '%'
          }
        },
        // 视觉映射组件
        visualMap: {
          show: true,
          type: "continuous",
          min: 0,
          max: 100,
          text: [
            "High",
            "Low"
          ],
          textStyle: {
            color: "#ddd"
          },
          inRange: {
            color: [
              "#fff5e5",
              "#ffcb80",
              "#FF9700"
            ]
          },
          calculable: true,
          inverse: false,
          splitNumber: 5,
          dimension: 0,
          orient: "vertical",
          top: "center",
          showLabel: true,
          itemWidth: 20,
          itemHeight: 140,
          borderWidth: 0
        },
        series: [
          {
            type: 'map', // 类型
            // 系列名称，用于tooltip的显示，legend 的图例筛选 在 setOption 更新数据和配置项时用于指定对应的系列
            name: 'world map',
            mapType: 'world', // 地图类型
            // 图形上的文本标签
            label: {
              show: false, // 是否显示对应地名
              position: "top",
              margin: 8
            },
            // 地图区域的多边形 图形样式
            itemStyle: {
              emphasis: {
                label: {
                  show: null
                },
                areaColor: "#FF9700"
              }
            },
            emphasis: {},
            roam: false,
            aspectScale: 0.8,
            nameProperty: "name",
            selectedMode: false,
            zoom: 1.2,
            mapValueCalculation: "sum",
            showLegendSymbol: false,
            rippleEffect: {
              show: true,
              brushType: "stroke",
              scale: 2.5,
              period: 4
            },
            // 自定义地区的名称映射
            nameMap: mapData.namemap,
            // 地图系列中的数据内容数组 数组项可以为单个数值
            data: this.worldmapData
          }
        ]
      })
    },

    async getData(){
      const report_id = this.$util.getParams(window.location.href).report_id
      const result = await this.$store.dispatch("getReport", {
        report_id: report_id
      })
      if (result.code === 2000) {
        this.handleData(result.reports)
        this.loading=false
      } else {
        this.$message({
          message: result.msg,
          type: "error"
        })
      }
    },

    handleData(report){
      this.videoElement = document.getElementsByTagName('video')
      let time = report.update_time.substring(0,report.update_time.indexOf("T"));
      this.songData = report
      this.songData.multi_layer_result = report.pdm_result
      this.songData.artist_name = report.artist
      this.songData.update_time = time
      this.pdmResult = report.pdm_result || []
      let isNigeriaModel = false
      const item = this.pdmResult.find(i => i.model_name.includes('Nigeria'))
      if (item && item.score >= 85) {
        isNigeriaModel = true
      }
      if (report.playlist) {
        this.playlistObj = report.playlist
        // 歌单标签
        this.platformTags = this.playlistObj.playlist_tags.filter(item => item.spotify_count)
        // 歌单数据
        this.totalPlaylist = report.playlist.spotify_playlist
        this.playlists = this.totalPlaylist.slice(0, 5)
        if (this.playlistObj.boomplay_playlist) {
          const set = new Set()
          const bp_playlist = this.playlistObj.boomplay_playlist || []
          bp_playlist.forEach(item => {
            set.add(item.region)
          })
          this.regionList = Array.from(set)
        }
      }
      
      if (report.audience_prediction){
        // 世界地图 Echart
        let map = []
        let data = report.audience_prediction.world_map_chart
        for(let i in data){
          let a ={}
          a.name = data[i][0]
          a.value = data[i][1]
          map.push(a)
        }
        this.worldmapData = map.sort((a,b) => b.value - a.value)
        const index = this.worldmapData.findIndex(item => item.name === 'Nigeria')
        // 是尼日利亚模型，模型打分大于85分，且尼日利亚不在top30国家地区中
        // 满足这种情况时，把尼日利亚位置调到第10
        if (isNigeriaModel && index !== -1 && this.worldmapData[index].value < this.worldmapData[29].value) {
          this.worldmapData[index].value = this.worldmapData[9].value
        }
        this.worldmapData.sort((a,b) => b.value - a.value)
        let sortArr = JSON.parse(JSON.stringify(this.worldmapData))
        this.region10 = sortArr.slice(0, 10)
        this.region20 = sortArr.slice(10, 20)
        this.region30 = sortArr.slice(20, 30)
      }else{
        this.worldmapData =[]
      }
      this.drawMap()
      
      // 宣推建议
      if(report.promotion && report.promotion.promotion_type){
        let typeList = []
        this.promotionData = report.promotion.promotion_type
        for (let i in this.promotionData){
          typeList.push(this.promotionData[i].type)
        }
        this.videoTypes = typeList
        this.videoType = 0
        this.videoUrlList = this.promotionData[0].videos
      }
    },

    playMusic(){
      this.audioPlayer = document.getElementById('audioPlayer')
      if (this.audioPlayer.paused) {
          this.audioPlayer.play()
          this.isAudioPlay=1
        } else {
          this.audioPlayer.pause()
          this.isAudioPlay=0
        }
    },

    changeVideoType(){
      this.videoUrlList = this.promotionData[this.videoType].videos
    },

    handlePlay (index) {
      const videoElement = this.videoElement
      if (videoElement && videoElement.length > 0) {
        for (let i = 0; i < videoElement.length; i++) {
          if (i === index) {
            this.videoElement[i].play()
          } else {
            this.videoElement[i].pause()
          }
        }
      }
    },

    changePlaylist (currentPage) {
      this.currentPage = currentPage
      const page = currentPage - 1
      this.playlists = this.totalPlaylist.slice(page*5, page*5 + 5)
    },

    filterPlaylist () {
      // 过滤出符合条件的歌单
      const playlist = this.playlistObj[this.playlistsCompany] || []
      this.totalPlaylist = playlist.filter(item => this.handlePlaylist(item))
      // 回到第一页
      this.changePlaylist(1)
    },

    handlePlaylist (item) {
      // 过滤歌单类型
      if (this.theme !== 'All' && this.theme !== item.type) return false
      // 过滤创建者类型
      if (this.creator !== 'All' && this.creator !== item.official) return false
      // 过滤地区
      if (this.playlistsCompany === 'boomplay_playlist' && this.region !== 'All' && this.region !== item.region) return false
      // 过滤平台
      if (this.playlistsCompany !== 'spotify_playlist' && this.playlistsCompany !== 'boomplay_playlist') return true
      // 过滤likes range
      const min = this.formatTooltip(this.likesRange[0])
      const max = this.formatTooltip(this.likesRange[1])
      if (min > +item.likes || (+item.likes > max && max < 100000)) return false
      return true
    }
  }
}
</script>

<style lang="stylus" scoped>
.report-template {
  padding: 40px;
  margin: 100px auto;
  width: 1000px;
  background-color #fff;
  color: #333;
  font-size: 16px;
  user-select: text;
  position: relative;
  z-index: 2;

  .gradient-bg {
    position: absolute;
    z-index: 1;  
    top: 0;
    left: 0;
    opacity: 0.25;
    background: linear-gradient(to bottom, #FF9700 0%, #fff 100%);
    width: 1080px;
    height: 160px;
  }

  .pdm-logo {
    width: 180px;
    margin-top: 10px;
  }

  .report-name {
    font-size: 32px;
    text-align: left;
    color: #FF9700;
    margin-top: 60px;
    font-family: 'Gilroy Bold';
  }
  
  .song-table {
    width: 100%;
    border-collapse:collapse;
    border-radius: 6px;
    overflow: hidden;
    .track-info{
      td{
        text-align: center;
      }
    }
    th {
      height: 56px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 600;
    }
    td {
      height: 56px;
      text-align: left;
      line-height: 26px;
      font-weight: 400;
      padding: 10px;
    }
    tr:nth-child(2n) {
      background-color: #fbfbfb;
    }
    tr:nth-child(2n+1) {
      background-color: #f3f3f3;
    }
    .section{
      color: #FF9700;
      font-size: 20px;
      font-weight:600;
      text-align: center;
      width: 160px;
      min-height: 90px;
    }
    .score {
      color: #FF9700;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .template-box {
    margin: 0 auto;
    padding: 10px 0 30px;
    width: 100%;
    
    .title {
      font-size: 20px;
      margin: 20px 0;
      line-height: 30px;
      font-weight: 600;
    }
    .title-circle {
      display: inline-block;
      height: 11px;
      width: 11px;
      border-radius: 11px;
      background: #FF9700;
      margin: 0 5px 3px 0;
    }
    .second-title{
      margin: 20px 0;
      color: #333;
      font-size: 16px;
      line-height: 30px;
    }
    .track-img{
      width: 1000px;
      margin: 0 50px;
    }
    .video-type {
      display: flex;
      align-items: center;
      margin: 10px 0 15px 0;
    }
    .video-type-group {
      flex: 1;
      font-size: 16px;
      margin-top: 5px;
    }
    .video-list{
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
    }
    .video-item{
      width: 260px;
      height: 450px;
      background-color: #eee;
      border-radius: 8px;
      margin: 0 100px 80px 0;
    }
    .video-item:nth-child(3n) {
      margin: 0 0 80px 0;
    }
    .tags-box{
      margin: 20px 0;
      border: 1px solid #dedede;
      border-radius: 4px;
      width: 50%;
      padding: 10px 0; 
      font-size: 16px;
      .tags{
        padding: 10px 20px;
        display:flex;
        flex-wrap: wrap;
        height: 27px;
        line-height: 27px;
      }
      .tag-type{
        margin: 0 5px;
      }
      .tag{
        padding: 0 20px;
        margin: 0 10px;
        background-color: #fff4eb;
        border-radius: 25px;
        border: 1px solid #dedede;
      }
    }
    .playlist-box{
      width: 70%
      flex: 1;
      padding: 15px 30px;
      border: 1px solid #dedede;
      .playlist-div {
        height: 520px;
      }
      .playlist-item{
        display: flex
        font-size: 13px;
        height: 80px;
        line-height: 30px;
        padding: 12px 0;
        border-bottom: 1px solid #dedede;
        cursor: pointer;
      }
      .playlist-item:last-child {
        border: none;
      }
      .playlist-item:hover {
        background: #fff4eb;
        .playlist-link {
          color: #FF9700;
        }
      }
      .img-box { 
        width: 80px;
        height: 80px;
        position: relative;
        margin:0 30px;
        .search-img {
          width: 80px;
          height: 80px;
        }
      }
      .info-box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .playlist-link {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
      }
    }
    .chart-box{
      width: 100%;
      .map-chart {
        width: 90%;
        height: 500px;
        margin: 20px auto;
      }
    }

    .top30-region {
      padding: 0 100px 0 20px;
    }

    .top30-text {
      font-size: 20px;
      font-family: 'Gilroy Bold';
      font-weight: bold;
      color: rgba(0,0,0,0.85);
      margin-bottom: 20px;
      text-align: left;
    }
    
    .top-10, .top-20, .top-30 {
      display: flex;
      flex-wrap: wrap;

      div {
        border: 1px solid;
        border-radius: 50px;
        padding: 5px 15px;
        margin: 0 10px 14px 0;
      }
    }

    .top-10 div {
      border: 2px solid #D6411D;
      color: #D6411D;
    }

    .top-20 div {
      border: 2px solid #FF9700;
      color: #FF9700;
    }

    .top-30 div {
      border: 2px solid #A0A0A0;
      color: #A0A0A0;
    }
    
    .player {
      color: #aaa;
      cursor: pointer;
      font-size: 28px;
      line-height: 30px;
    }
    ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner{
      background-color:#fff;
      color:#FF9700;
      font-weight:600;
      border: 1px solid #FF9700
      box-shadow:0 0 #fff
    }
  }
  .filter-box {
    margin: 20px 0 30px;
    display: flex;
    .filter-item {
      display: flex;
      align-items: center;
      margin-right: 40px;
      span {
        padding-right: 10px;
      }
      .slider {
        width: 340px;
        margin: 0 20px 0 10px;
      }
      .el-select {
        width: 180px;
      }
    }
    .filter-item:last-child {
      margin-right: 0;
    }
  }
  .email-us {
    width: 900px;
    text-align: center;
    margin: 80px auto 20px;
    font-size: 13px;
    color: #bbb;
    user-select: text;
    padding-top: 10px;
  }
  .video-empty {
    width: 70%; 
    margin: 70px auto 0; 
    overflow: hidden; 
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  .pdm-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 230px;
  }
}
::v-deep .el-slider__stop {
  background: #e4e7ed;
}
</style>